

import React, { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getScreenshot } from "./authSlice"
import * as faceapi from 'face-api.js'
import { detectFaces, getStyles, setFacenetError } from "./facenetSlice"
import axios from "axios";
import {useNavigate} from "react-router-dom";

export const Preview = (props) => {
    const dispatch = useDispatch()
    const screenshot = useSelector(getScreenshot)
    const styles = useSelector(getStyles)
    const canvasRef = useRef()
    const previewRef = useRef()
    const imageRef = useRef('image')
    const imageStaticRef = useRef('imageStatic')
    const navigation = useNavigate()
    const base64ToFile=(base64, filename)=> {
        const byteString = atob(base64.split(',')[1]);
        const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        const file = new File([blob], filename, { type: mimeString });

        return file;
    }
    const handleScreenshot = async (blob) => {

        // const { blobW, blobH } = await getScreenshotDims(blob);
        // cropImage(blobW, blobH);
        dispatch(setFacenetError(null));
        const result = await dispatch(detectFaces({ previewRef, canvasRef }));
        const faces = result.payload;

        if (faces && faces.length > 0) {
            let options = {
                lineWidth: 4,
                boxColor: (faces.length === 1 && faces[0].detection.score > 0.75) ? '#00ff00' : '#f00000',
                drawLabelOptions: { fontColor: '#1e40af' }
            };
            faces.map(face => {
                const box = face.detection.box;
                options['label'] = 'Score: ' + (Math.round(face.detection.score * 100) / 100);
                const drawBox = new faceapi.draw.DrawBox(box, options);
                return drawBox.draw(canvasRef.current);
            });
            console.log(blob)
            const filename = "screenshot.jpg";
            const file = base64ToFile(blob, filename);

            const formData = new FormData();
            formData.append('image', file);
            console.log(props)

                try {
                 await axios.post(`https://api-lms.tsue.uz/api/compare-student-image/${localStorage?.getItem('student')}/`, formData,{
                     headers:{
                         'Content-type':'multipart/form-data'
                     }
                 }).then(
                        (response) => {
                            sessionStorage.setItem('access',response.data.access)
                            sessionStorage.setItem('studentId',localStorage.getItem('student'))
                            navigation('/')
                            console.log(response);
                        }
                    )
                } catch (error) {
                localStorage.setItem('faceError', error.response?.data?.message);
                alert(error.response?.data?.message)
                    console.error('Error uploading image:', error);
                }

        }
    };
    return (
        <>
        <div className="preview">

            {screenshot != null && <>
                    <canvas id="preview-canvas" ref={canvasRef} style={styles.canvas}></canvas>
                    <img ref={previewRef} src={screenshot} alt="preview" style={styles.preview} onLoad={() => handleScreenshot(screenshot)} />
                    <img style={{display:'none'}} ref={imageRef} src={screenshot} alt="preview"   />
                    <img style={{display:'none'}} ref={imageStaticRef} src='/images/photo_2024-01-31_19-58-13.jpg' alt="preview"   />
                </>
            }
        </div>
        <div className={'camera-overlay ' + (screenshot != null ? 'hidden' : '')} />
        </>
    )
}