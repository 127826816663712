import React, {useEffect, useState} from 'react';
import {Button, Table} from "antd";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import './Exam.scss'
import {setDocumentTitle} from "../Store/DocumentTitle";

const Exam = () => {
    const [exam, setExam] = useState([])
    const navigation =useNavigate()
    const columns = [
        {
            title: 'Nomi',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <p>{record.exam.name}</p>
            ),
        },
        {
            title: 'Savollar soni',
            width: '7%',
            render: (_, record) => (
                <p>
                    {record.exam.total_count}
                </p>
            ),
        },
        {
            title: 'Max. ball',
            width: '7%',
            render: (_, record) => (
                <p>
                    {record.exam.max_score}
                </p>
            ),
        },
        {
            title: 'Boshlanish',
            width: '12%',
            render: (_, record) => (
                <p>
                    {formatter(record.exam.begin_time)}
                </p>
            ),
        },
        {
            title: 'Tugash',
            width: '12%',
            render: (_, record) => (
                <p>
                    {formatter(record.exam.end_time)}
                </p>
            ),
        },
        {
            title: 'Vaqt',
            width: '5%',
            render: (_, record) => (
                <p>
                    {record.exam.exam_time}
                </p>
            ),
        },
        {
            title: 'Kirish',
            width: '8%',
            render: (_, record) => (
                <>
                    {record.is_start ? <>
                            {record.is_start && record.is_finish?<>
                                <Button onClick={()=>goToTest(record.exam.id, record.id,record.exam.name)} size={"large"} disabled={!record.is_active || !record.exam.exam_status||!record?.exam?.is_enabled} type={"primary"}>
                                    Boshlash
                                </Button>
                            </>:<>
                                <Button onClick={()=>goToTestCheck(record.exam.id,record.exam.name)} size={"large"} disabled={!record.is_active || !record.exam.exam_status} type={"primary"}>
                                    Davom etish
                                </Button>
                            </>

                            }

                        </>
                        :
                        <>
                            <Button disabled={!record.is_active || !record.exam.exam_status||!record?.exam?.is_enabled} onClick={()=>goToTest(record.exam.id, record.id,record.exam.name)} size={"large"} type={"primary"}>
                                Boshlash
                            </Button>
                        </>

                    }

                </>
            ),
        },
    ];
    const getExams = () => {
        axios.get(`https://api-lms.tsue.uz/api/exam-list/${sessionStorage.getItem('student_uid_id')}`)
            .then((res) => {
                setExam(res.data)
            })
            .catch((err) => {
            })
            .finally(() => {
            });
    }
    const formatter = (time) => {
        const isoDate = new Date(time);

        // Format date using toLocaleString
        return isoDate.toLocaleString('uz-UZ', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    }
  const   goToTest =(exam_id,name)=> {
        axios.get(`https://api-lms.tsue.uz/api/check-attempts/${sessionStorage.getItem('student_uid_id')}/for/${exam_id}`).then((res)=>{
            if(res.status){
                setDocumentTitle(name)
                navigation(`/exam-started/${exam_id}`)
            }
        }).catch(()=>{

        })


    }

        const fetchIpAddress = async (exam_id,name) => {
            try {
                const response = await axios.get('https://api-lms.tsue.uz/api/get-ip-address/');
                axios.post("https://api-lms.tsue.uz/api/check-continue/", {
                    exam: exam_id,
                    student: sessionStorage.getItem('student_uid_id'),
                    ip_address: response.data.client_ip_address.split(',')[0]
                }).then(() => {
                    setDocumentTitle(name)
                    navigation(`/exam-started/${exam_id}`)
                }).catch((err) => {
                    // this.notificationMessage(err.response.data.message, "error");
                })
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }
        };


    const goToTestCheck=(exam_id,name)=> {
      fetchIpAddress(exam_id,name)
    }

    useEffect(() => {
        setDocumentTitle('Imtixonlar')
        getExams()
    }, []);
    return (
        <div>
            <Table scroll={{
                x: 1000
            }} columns={columns} pagination={false} dataSource={exam}/>
        </div>
    );
};

export default Exam;