import React, { useState, useEffect } from 'react';
import {Button, Form, Input, Segmented} from "antd";
import './login.scss'
import {UserOutlined} from "@ant-design/icons";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

const AppLogin = () => {
    const [facesMatched] = useState(null);
    const [value, setValue] = useState('Kirish');
    const [loading,setLoading] = useState(false)
    const navigation = useNavigate()

    const onFinish = (values) =>{
        setLoading(true)
        axios.post("https://api-lms.tsue.uz/api/auth/login", values)
            .then((data) => {
                if (data.data.success) {
                    if (Number(data.data.data.educationForm.code) === 16) {
                        sessionStorage.setItem('access',data.data.token)
                        sessionStorage.setItem('studentId',data.data.data.student_id_number)
                        navigation('/')
                        setLoading(false)

                    } else {
                        toast.error('Siz tizimdan foydalana olmaysiz', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                }
            })
            .catch(() => {
                toast.error('Login yoki parol xato!', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .finally(() => {
                setLoading(false)
            });
    }
    const changeRoute= (value) =>{
        setValue(value)
        if(value === 'Kirish'){
            navigation('/login')

        }else {
            navigation('/faceId')


        }
    }
    useEffect(() => {
    }, [facesMatched]);

    return (
        <div  className="auth">
            <ToastContainer/>
            <div className="auth__content">
                <Segmented className='segment' size={"large"} style={{background:'#1677ff'}} options={['Kirish','Face id orqali kirish']}  value={value} onChange={changeRoute} />
                <div className="auth__wrap">
                        <div className="auth__logo">
                            <img src="/media/svg/logo.png" alt="" width={120} className="mb-5" />
                        </div>
                        <div className='title-otm'>
                            Toshkent davlat
                            iqtisodiyot universiteti
                        </div>
                        <div className='subtitle-otm'>
                            Masofaviy ta'lim tizimi
                        </div>
                    <Form
                        className='basic-form'
                        layout={'vertical'}
                        name="basic"
                        style={{
                            maxWidth: 350,
                            width:'100%'
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            style={{width:'100%'}}
                            label="ID raqam"
                            name="login"
                            rules={[
                                {
                                    required: true,
                                    message: 'Iltimos id raqamingizni yozing!',
                                },
                            ]}
                        >
                            <Input  suffix={<UserOutlined />} size='large'/>
                        </Form.Item>

                        <Form.Item

                            label="Parol"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Iltimos parolingizni yozing!',
                                },
                            ]}
                        >
                            <Input.Password  size='large' />
                        </Form.Item>
                        <Form.Item style={{width:'100%'}}>
                            <Button loading={loading} style={{width:'100%'}} size={"large"} type="primary" htmlType="submit">
                                Kirish
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <div className="auth__photo">
                <img src='/media/svg/logo.png' width={200} height={200} alt='Logo TFI' draggable={"false"} style={{userSelect:'none'}}/>
            </div>
        </div>
    );
};

export default AppLogin;
