import React, {useEffect, useState} from 'react';

import axios from "axios";
import './Resources.scss'
import {setDocumentTitle} from "../Store/DocumentTitle";
import {useAppContext} from "../Store/Store";
import ResourcesItem from "./resources-list-item/resources-item";
const Resources = () => {
    const [subjects, setSubjects] = useState([])
    const {getInfo} = useAppContext()
    const getSubjects = () => {
        axios.get(`https://api-lms.tsue.uz/api/student/content/get_all_active/?group_id=${getInfo()?.group?.id}`)
            .then((res) => {
                setSubjects(res.data.results)
            }).catch(() => {

        })
    }
    useEffect(() => {
           setDocumentTitle('Fan resurslari')
            getSubjects()



    }, [getInfo]);

    return (

       
 <div className='resources'>
 {subjects?.map((item) => (
     <>
         <ResourcesItem subject={item}/>
         {/*<Card*/}
         {/*    key={item.id}*/}
         {/*    title={item.subject_name}*/}
         {/*    className='card-resource'*/}
         {/*    style={{*/}
         {/*        maxWidth: 400,*/}
         {/*        width:'100%'*/}
         {/*    }}*/}
         {/*>*/}
         {/*    <NavLink style={{width: '100%', color: "black"}} to={`/resources-list/${item.id}`}>*/}
         {/*        <Card.Grid style={{width: "100%", cursor: 'pointer'}}>Resurslar</Card.Grid>*/}
         {/*    </NavLink>*/}
         {/*    <NavLink style={{width: '100%', color: "black"}} to={`/task/${item.id}`}>*/}
         {/*        <Card.Grid style={{width: "100%", cursor: 'pointer',marginTop:'5px'}}>Topshiriqlar</Card.Grid>*/}
         {/*    </NavLink>*/}
         {/*</Card>*/}
     </>

 ))
 }

</div>
        
    )
}

export default Resources;